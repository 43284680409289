/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React      from 'react';
import PropTypes  from 'prop-types';
import { Helmet } from 'react-helmet';

function Seo( { description, lang, meta, title, imageLink } ) {
  const metaDescription = description;

  return (
    <Helmet htmlAttributes={ { lang } }
            title={ title }
            titleTemplate={ `%s` }
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                name: `image`,
                property: `og:image`,
                content: imageLink
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:site`,
                content: `@MartechMonkeys`,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:creator`,
                content: '@MartechMonkeys',
              },
              {
                name: `twitter:domain`,
                content: 'https://zweistein.io',
              },
              {
                name: `twitter:image`,
                content: imageLink
              }
            ].concat( meta ) }
    />
  )
}


Seo.defaultProps = {
  description: '',
  meta: [],
  title: '',
  lang: 'de',
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf( PropTypes.object ),
  title: PropTypes.string.isRequired,
  imageLink: PropTypes.string
};

export default Seo;
