import styled from 'styled-components';

export const ResetPasswordElement = styled.div`
  --width: 560px;
  
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--theme-deep-purple);
  z-index: 10;
  
  .icon {
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
  }
  
  .title {
    position: relative;
    min-width: 178px;
    margin: 0 0 30px 0;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: var(--white);
    
    &::after {
      --shift: 8px;
      
      content: '';
      position: absolute;
      left: calc(var(--shift) / -2);
      bottom: -4px;
      width: calc(100% + var(--shift));
      height: 1px;
      background-color: var(--white);
    }
  }
  
  .description {
    position: relative;
    margin: 0 0 30px 0;
    text-align: center;
    font-weight: 400;
    letter-spacing: 1.5px;
    color: var(--white);
  }
  
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .error {
    margin: 0 0 20px 0;
    text-align: center;
    font-weight: 600;
    letter-spacing: .75px;
    color: var(--theme-error);
  }
  
  .field {
    --height: 70px;
    
    width: var(--width);
    height: var(--height);
    border: 2px solid var(--white);
    border-radius: 38px;
    padding: 0 35px;
    text-transform: initial;
    color: var(--white);
    background-color: transparent;
    transition: .3s all ease-in-out;
    
    &:focus {
      outline: none;
    }
    
    &::placeholder {
      text-transform: uppercase;
      color: var(--theme-dark-gray);
    }
    
    &--is-invalid {
      border-color: var(--theme-error);
    }
    
    &-group {
      position: relative;
      margin-bottom: 20px;
    }
    
    &__icon {
      --dimensions: 28px;
    
      position: absolute;
      top: calc(50% - (var(--dimensions) / 2));
      right: 25px;
      width: var(--dimensions);
      height: var(--dimensions);
      
      &:hover {
        cursor: pointer;
      }
      
      &__item {
        width: var(--dimensions);
        height: var(--dimensions);
      }
    }
  }
  
  .btn {
    margin-top: 15px;
    will-change: transform;
    transition: all 0.2s ease;
    
    &.processing {
      --btn-height: 70px;
    
      width: var(--btn-height);
      height: var(--btn-height);
      border: 2px solid var(--theme-blue);
      border-radius: calc(var(--btn-height) / 2);
      font-size: 0;
      background: transparent;
      border-right-color: var(--white);
      animation: loadingBtn 0.4s linear infinite !important;
    }
  }
  
  .success {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s, visibility .5s;
    animation: fadeInRightMsg .5s ease forwards;
  }
  
  .remember {
    position: relative;
    margin-bottom: 15px;
    
    &__mark {
      display: none;
      
      &:checked {
        & + label {
          &::after {
           background-color: var(--theme-blue);
          }
        }
      }
    }
    
    &__label {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: var(--theme-dark-gray);
      
      &.is-checked {
        & + label {
          &::after {
            background-color: var(--theme-blue);
          }
        }
      }
      
      &::after {
        content: '';
        display: flex;
        width: 15px;
        height: 15px;
        border: 2px solid var(--theme-light-gray);
        margin-left: 30px;
        background-color: var(--theme-light-gray);
      }
      
      &:hover {
        cursor: pointer;
      }
    }
  }
  
  .error {
    max-width: 560px;
    text-align: center;
    letter-spacing: .75px;
    line-height: 19px;
    color: var(--theme-error);
  }
  
  /*-------------------------
  # fadeInRightMsg
  --------------------------*/
  @keyframes fadeInRightMsg {
    0% {
      opacity: 0;
      visibility: visible;
      left: 50px;
    }
    
    100% {
      opacity: 1;
      visibility: visible;
      left: 0;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1440px) {
    .field-group {
      margin-bottom: 15px;
    }
    
    .btn {
      &.processing {
        --btn-height: 57px;
      }
    }
  }
  
  @media (max-width: 1280px) {
    .field {
      --height: 65px;
      
      &-group {
        margin-bottom: 10px;
      }
    }
  }
  
  @media (max-width: 1024px) {
    .field {
      --width: 520px;
      --height: 60px;
    }
    
    .description,
    .title,
    .error {
      margin-bottom: 15px;
    }
    
    .remember {
      margin-bottom: 10px;
    }
    
    .btn {
      margin-top: 10px;
    }
  }
  
  @media (max-width: 991px) {
    .error {
      z-index: 1;
    }
  }
  
  @media (max-width: 767px) {
    .btn {
      &.processing {
        --btn-height: 40px;
      }
    }
  }
  
  @media (max-width: 640px) {
    .field {
      &__icon {
        right: 20px;
      }
    }
  }
  
  @media (max-width: 480px) {
    --width: 100%;
    
    padding: 100px 30px 0 30px;
    
    .form,
    .field-group {
      width: var(--width);
    }
    
    .field {
      --height: 56px;
      width: 100%;
    }
    
    .close {
      &__icon {
        top: 15px;
        right: 15px;
      }
    }
  }
  
  @media (max-width: 375px) {
    padding-top: 130px;
  }
`;